// lib/handleSetImage.js
import compressImage from './compressImage'
import { directUpload } from './directUpload'

export default function handleSetImage(
  e,
  setImage,
  setFileError,
  onProgress = () => {},
  setUploadStatus = () => {}
) {
  if (e.target.files.length) {
    const file = e.target.files[0]

    compressImage(
      async (compressedFile) => {
        try {
          const result = await directUpload(
            compressedFile,
            (progress) => onProgress(progress),
            (status) => setUploadStatus(status)
          )
          setImage(result)
        } catch (err) {
          setFileError(err.message)
          setUploadStatus('Upload failed')
        }
      },
      file,
      {},
      setFileError
    )
  } else {
    setImage(null)
  }
}
