import ReactModal from 'react-modal'
import colors from '../../../config/colors'
import Icon from '../Icon'

ReactModal.setAppElement('#communitylab')

export default function BaseModal({
  children,
  onRequestClose,
  closeIcon,
  title,
  titleStyle,
  contentStyle = {},
  ...props
}) {
  const defaultStyle = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(87, 91, 91, 0.70)',
      display: 'grid',
      placeItems: 'center',
      overflowY: 'auto',
      WebkitOverflowScrolling: 'touch',
      padding: '0px',
      zIndex: 101,
    },
    content: {
      position: 'relative',
      top: 0,
      left: 0,
      minWidth: '250px',
      maxWidth: '720px',
      padding: '20px',
      display: 'grid',
      background: colors.white,
      borderRadius: '4px',
      outline: 'none',
      boxSizing: 'content-box',
      overflow: 'unset',
      placeItems: 'center',
      width: '100%',
      maxHeight: '80vh',
      overflowY: 'scroll',
      ...contentStyle,
    },
  }

  return (
    <ReactModal
      {...props}
      onRequestClose={onRequestClose}
      closeTimeoutMS={300}
      style={defaultStyle}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
    >
      {(closeIcon || title) && (
        <div className="flex flex-row w-full px-2 md:px-0 justify-between mb-3">
          <h2 className={`font-bold text-xl ${titleStyle} pointer-events-none`}>
            {title}
          </h2>
          {closeIcon && (
            <button
              onClick={onRequestClose}
              className="cursor-pointer"
              aria-label="Close modal"
            >
              <Icon name="times" />
            </button>
          )}
        </div>
      )}
      {children}
    </ReactModal>
  )
}
