/* eslint-disable no-bitwise */
import { yupResolver } from '@hookform/resolvers/yup'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useLeavePageConfirmation } from '../../lib/useLeavePageConfirmation'
import useReplyCrud from '../../lib/useReplyCrud'
import useUser from '../../lib/useUser'
import ChatResource from '../chat/ChatResource'
import CLAvatar from '../common/CLAvatar'
import CLEditor from '../common/CLEditor'
import Icon from '../common/Icon'
import { useAnalytics } from '../context/AnalyticsProvider'
import FilePreview from './common/FilePreview'
import replySchema from './schemas/reply'

const Reply = ({ itemId, itemType, item, videoEnabled }) => {
  const { user } = useUser()
  const { trackData } = useAnalytics()
  const { createReply } = useReplyCrud(itemId)
  const [submitting, setSubmitting] = useState(false)
  const [changesWithInput, setChangesWithInput] = useState(false)
  const [resourceVisible, setResourceVisible] = useState(false)
  const [image, setImage] = useState(null)
  const [file, setFile] = useState(null)
  const [isLink, setIsLink] = useState(false)
  const [isFile, setIsFile] = useState(false)
  const [videoId, setVideoId] = useState(null)

  const {
    control,
    register,
    handleSubmit,
    reset,
    trigger,
    setValue,
    clearErrors,
    getValues,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    reValidateMode: 'onChange' | 'onBlur' | 'onSubmit',
    resolver: yupResolver(replySchema),
    defaultValues: {
      content: null,
      link: null,
      isImage: false,
      isLink: false,
      isFile: false,
      altText: null,
      isVideo: false,
    },
  })
  const watchFields = useWatch({ control })
  useLeavePageConfirmation(
    changesWithInput,
    'Are you sure you want to discard this reply?'
  )

  useEffect(() => {
    if (
      watchFields?.content ||
      watchFields?.link ||
      watchFields?.isImage ||
      watchFields?.isLink ||
      watchFields?.isFile
    ) {
      setChangesWithInput(true)
    } else {
      setChangesWithInput(false)
    }
  }, [watchFields])

  useEffect(() => {
    setValue('isImage', !!image?.raw)
    setValue('isLink', isLink)
    setValue('isFile', isFile)
  }, [image, isLink, isFile])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(null)
      setChangesWithInput(false)
    }
  }, [isSubmitSuccessful])

  const onSubmit = async (data) => {
    if (submitting) {
      return
    }

    setSubmitting(true)
    const { content, link, altText } = data

    const body = {
      content_html: content?.html,
      content_delta: JSON.stringify(content?.delta),
      link,
      image: image?.signedId,
      file: file?.signedId,
      alt_text: altText || 'An image sent on Chat on Community Lab.',
      postable_type: itemType,
      postable_id: itemId,
      video_id: videoId,
    }

    try {
      await createReply(body)
      setSubmitting(false)

      const mixpanelEvent = itemType
        ? `${itemType?.toLowerCase()}_reply`
        : 'reply'
      trackData(mixpanelEvent, {
        type: itemType,
        item,
        user,
      })
    } catch (error) {
      console.error('An unexpected error happened:', error)
      setSubmitting(false)
    }

    reset({ content: null, link: null })
    setImage(null)
    setFile(null)
    setIsLink(false)
    setIsFile(false)
    setSubmitting(false)
    setValue('isImage', false)
    setValue('isLink', false)
    setValue('isFile', false)
    setValue('isVideo', false)
    setVideoId(null)
    clearErrors()
  }

  return (
    <>
      {user && (
        <div className="flex flex-col">
          <div className="flex flex-row items-start px-0 lg:px-2">
            <div className="self-start hidden md:flex mr-4">
              <CLAvatar
                key={`${user?.name}-reply-form-avatar`}
                avatar={user?.avatar}
                name={user?.name}
                className="mb-2 flex-shrink-0"
              />
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-1 relative w-full mb-2"
            >
              <Controller
                name="content"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CLEditor
                    mini
                    placeholder="Write a response"
                    value={value?.delta}
                    onChange={onChange}
                    padded
                    paddedLg
                    error={
                      errors?.content?.message || errors?.content?.html?.message
                    }
                    delta
                    groupId={
                      itemType?.toLowerCase() === 'event'
                        ? item?.eventable?.id
                        : item?.postable?.id
                    }
                  />
                )}
              />
              <button
                type="button"
                onClick={() => setResourceVisible(true)}
                disabled={submitting}
                className="absolute bottom-2 right-12"
                aria-label="Attach resource"
              >
                <Icon name="paperclip" />
              </button>
              <button
                type="submit"
                className="absolute bottom-1 right-4"
                disabled={submitting}
                aria-label="Submit"
              >
                <Icon name="plane" />
              </button>
              <ChatResource
                modalVisible={resourceVisible}
                onRequestClose={() => setResourceVisible(false)}
                register={register}
                setImage={setImage}
                setFile={setFile}
                image={image}
                file={file}
                reset={reset}
                linkError={errors?.link?.message}
                trigger={trigger}
                getValues={getValues}
                setValue={setValue}
                videoId={videoId}
                setVideoId={setVideoId}
                videoEnabled={videoEnabled}
              />
            </form>
          </div>
          <div>
            {image && (
              <FilePreview
                preview={image.preview || image}
                filename={image?.raw?.name || 'Image'}
                type="image"
                onClick={() => setImage(null)}
                hideLabel
              />
            )}
            {file && (
              <FilePreview
                preview={file?.preview}
                filename={file?.raw?.name || 'File attachment'}
                type="File"
                onClick={() => setFile(null)}
                hideLabel
              />
            )}
            {watchFields?.link && (
              <div className="text-blue-400 my-4 ml-6 md:ml-16">
                <Link
                  href={watchFields.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`${watchFields?.link.substring(0, 30)}...`}
                </Link>
                <button
                  onClick={() =>
                    reset({
                      link: null,
                    })
                  }
                  className="text-alert pl-2 text-xs"
                >
                  <Icon name="times" color="alert" size={18} />
                  Remove
                </button>
              </div>
            )}
            {videoId && (
              <div className="text-blue-400 my-2 ml-6 md:ml-16 flex flex-row justify-between">
                <p className="text-gray-400">Video attached to comment</p>
                <button
                  onClick={() => setVideoId(null)}
                  className="text-alert pl-2 text-xs"
                >
                  <Icon name="times" color="alert" size={18} />
                  Remove
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {!user && (
        <p className="text-offBlack text-center p-5">
          Join the platform to participate
        </p>
      )}
    </>
  )
}

export default Reply
