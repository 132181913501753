// components/common/ResourceRow/ResourceRow.js
import React, { useState } from 'react'
import { directUpload } from '../../../../lib/directUpload'
import handleSetImage from '../../../../lib/handleSetImage'
import validFileSize from '../../../../lib/validFileSize'
import BasicErrorMessage from '../../../common/BasicErrorMessage'
import FileUpload from './FileUpload'
import ImagePreview from './ImagePreview'
import LinkInput from './LinkInput'
import SubgroupButton from './SubgroupButton'
import UploadButton from './UploadButton'
import UppyUploadButton, {
  UppyDashboard,
  UppyEnableMessage,
} from './UppyUploadButton'

const ResourceRow = ({
  register,
  setImage,
  image,
  setFile,
  file,
  errors,
  setValue,
  getValues,
  videoId,
  setVideoId,
  allowVideoUpload,
}) => {
  const [fileError, setFileError] = useState(null)
  const [videoUppy, setVideoUppy] = useState(null)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploadStatus, setUploadStatus] = useState('')

  const handleSetFile = async (e) => {
    setFileError(null)
    setUploadStatus('')
    setUploadProgress(0)
    if (e.target.files.length) {
      const file = e.target.files[0]
      if (validFileSize(file, setFileError, 50)) {
        try {
          const result = await directUpload(
            file,
            (progress) => setUploadProgress(progress),
            (status) => setUploadStatus(status)
          )
          setFile(result)
        } catch (err) {
          setFileError(err.message)
          setUploadStatus('Upload failed')
          setUploadProgress(0)
        }
      }
    } else {
      setFile(null)
    }
  }

  const selectImage = (e) => {
    handleSetImage(
      e,
      setImage,
      setFileError,
      (progress) => setUploadProgress(progress),
      (status) => setUploadStatus(status)
    )
  }

  const handleSetResourceOption = (key) => {
    const defaultValues = {
      isImage: false,
      isFile: false,
      isLink: false,
      link: null,
      altText: null,
      isVideo: false,
    }

    Object.keys(defaultValues).forEach((field) => {
      setValue(field, defaultValues[field])
    })

    if (key) {
      setValue(key, true)
    }
  }

  const handleClearResource = () => {
    setImage(null)
    setFile(null)
    setVideoId(null)
  }

  return (
    <div className="flex flex-col mt-5">
      <div className="flex flex-col justify-start">
        <div className="flex flex-row flex-wrap justify-start items-start gap-2">
          <UploadButton
            htmlFor="upload-image-button"
            label="Image"
            iconName="image"
            isActive={getValues('isImage')}
            onClick={() => {
              handleClearResource()
              if (!getValues('isImage')) {
                handleSetResourceOption('isImage')
              }
            }}
            onChange={selectImage}
            accept="image/*"
          />
          <SubgroupButton
            isActive={getValues('isFile')}
            iconName="attachment"
            label="File"
            onClick={() => {
              handleClearResource()
              if (!getValues('isFile')) {
                handleSetResourceOption('isFile')
              } else {
                handleSetResourceOption()
              }
            }}
          />
          <SubgroupButton
            isActive={getValues('isLink')}
            iconName="link"
            label="Link"
            onClick={() => {
              handleClearResource()
              if (!getValues('isLink')) {
                handleSetResourceOption('isLink')
              } else {
                handleSetResourceOption()
              }
            }}
          />
          <UppyUploadButton
            allowVideoUpload={allowVideoUpload}
            setVideoUppy={setVideoUppy}
            setVideoId={setVideoId}
            getValues={getValues}
            handleSetResourceOption={handleSetResourceOption}
          />
        </div>
        <UppyEnableMessage
          allowVideoUpload={allowVideoUpload}
          videoId={videoId}
        />
        {getValues('isLink') && (
          <LinkInput
            errors={errors}
            register={register}
            selectImage={selectImage}
          />
        )}
        <BasicErrorMessage error={fileError} />
        {(uploadProgress > 0 || uploadStatus) && (
          <div className="mt-2">
            {uploadProgress > 0 && uploadProgress < 100 && (
              <div className="w-full h-2 bg-gray-200 rounded">
                <div
                  className="h-full bg-primary rounded"
                  style={{ width: `${uploadProgress}%` }}
                />
              </div>
            )}
            {uploadStatus && (
              <p className="text-sm text-gray-600 mt-1">{uploadStatus}</p>
            )}
          </div>
        )}
        {getValues('isFile') && (
          <FileUpload
            getValues={getValues}
            file={file}
            handleSetFile={handleSetFile}
            setFile={setFile}
            selectImage={selectImage}
            setFileError={setFileError}
          />
        )}
        {image && (
          <ImagePreview
            image={image}
            setValue={setValue}
            setImage={setImage}
            register={register}
            getValues={getValues}
          />
        )}
        {getValues('isVideo') && (
          <UppyDashboard videoUppy={videoUppy} videoId={videoId} />
        )}
      </div>
    </div>
  )
}

export default ResourceRow
