import React from 'react'
import FilePreview from '../FilePreview'
import UploadButton from './UploadButton'

const FileUpload = ({
  getValues,
  file,
  handleSetFile,
  setFile,
  selectImage,
}) => {
  const isFileUploadEnabled = getValues('isFile')

  return (
    <div className="space-y-4 mt-4">
      <div className="flex flex-col sm:flex-row gap-2">
        <UploadButton
          htmlFor="upload-file-button"
          label="Add your File"
          iconName="upload"
          onChange={handleSetFile}
          disabled={!isFileUploadEnabled}
        />
        <UploadButton
          htmlFor="upload-other-image-button"
          label="Add your Image"
          iconName="image"
          onChange={selectImage}
          accept="image/*"
        />
      </div>

      {file && (
        <FilePreview
          preview={file.preview}
          filename={file.raw?.name ?? 'File attachment'}
          type="File"
          onClick={() => setFile(null)}
        />
      )}
    </div>
  )
}

export default FileUpload
