/* eslint-disable no-unused-vars */
import Compressor from 'compressorjs'
import validFileSize from './validFileSize'

export default function compressImage(
  onSuccess,
  file,
  options = {},
  setFileError
) {
  const comp = new Compressor(file, {
    quality: 0.8,
    resize: 'contain',
    checkOrientation: true,
    maxHeight: 1200,
    width: 1200,
    ...options,
    success(result) {
      if (validFileSize(result, setFileError)) {
        const compressedFile = new File([result], result?.name, {
          type: file.type,
          lastModifiedDate: new Date(),
        })
        onSuccess(compressedFile)
      }
    },
    error(_err) {
      console.log(_err)
      setFileError?.(_err.message)
      return null
    },
  })
}
