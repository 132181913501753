/* eslint-disable no-undef */
import Script from 'next/script'
import { useEffect, useState } from 'react'
import useUser from '../lib/useUser'

const OneSignal = () => {
  const { user, updateUser } = useUser()
  const [oneSignalSDKLoaded, setOneSignalSDKLoaded] = useState(false)

  // Only proceed if we're in production or explicitly enabled for development
  const shouldInitializeOneSignal =
    process.env.NODE_ENV === 'production' ||
    process.env.NEXT_PUBLIC_ONESIGNAL_ENABLED === 'true'

  useEffect(() => {
    if (!oneSignalSDKLoaded || !shouldInitializeOneSignal) {
      return
    }

    // Safely check for window and OneSignalDeferred
    if (typeof window === 'undefined' || !window.OneSignalDeferred) {
      return
    }

    window.OneSignalDeferred.push(async function (OneSignal) {
      try {
        if (user?.slug) {
          await OneSignal.login(user.slug)
        }

        if (user !== null && updateUser !== null) {
          await updateUserOneSignalId(OneSignal, user, updateUser)
        }

        OneSignal.User.PushSubscription.addEventListener('change', async () => {
          updateUserOneSignalId(OneSignal, user, updateUser)
        })
      } catch (error) {
        console.error('OneSignal operation failed:', error)
      }
    })

    return () => {
      if (window.OneSignalDeferred) {
        window.OneSignalDeferred.push(async function (OneSignal) {
          OneSignal.User.PushSubscription.removeEventListener('change')
        })
      }
    }
  }, [oneSignalSDKLoaded, user?.slug, updateUser, shouldInitializeOneSignal])

  // Don't render anything in development unless explicitly enabled
  if (!shouldInitializeOneSignal) {
    return null
  }

  return (
    <>
      <Script
        id="onesignal-sdk"
        src="https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js"
        defer
        onLoad={() => initOneSignal(setOneSignalSDKLoaded, user, updateUser)}
      />
    </>
  )
}

const initOneSignal = async (setOneSignalSDKLoaded, user, updateUser) => {
  if (typeof window === 'undefined') {
    return
  }

  window.OneSignalDeferred = window.OneSignalDeferred || []

  window.OneSignalDeferred.push(async function (OneSignal) {
    try {
      await OneSignal.init({
        appId: process.env.NEXT_PUBLIC_ONESIGNAL_APPID,
        safari_web_id: process.env.NEXT_PUBLIC_ONESIGNAL_SAFARIID,
        notifyButton: {
          enable: false,
        },
        promptButton: { showCredit: false },
        allowLocalhostAsSecureOrigin: process.env.NODE_ENV === 'development',
        serviceWorkerParam: {
          scope: '/js/push/onesignal/',
        },
        serviceWorkerPath: './js/push/onesignal/OneSignalSDKWorker.js',
        serviceWorkerUpdaterPath:
          '/js/push/onesignal/OneSignalSDKUpdaterWorker.js',
      })

      await OneSignal.User.addTags({
        joined_platform: String(user?.onboarded_at) || 'unknown',
        groups_in: user?.group_memberships?.length || 0,
        clusters_followed: user?.followed_badges?.length || 0,
        input_location: user?.location ? user?.location : 'unknown',
        allow_marketing: user?.allow_marketing ? 1 : 0,
        profile_pic_uploaded: user?.avatar ? 1 : 0,
        managed_cluster_count: user?.managed_badges?.length || 0,
        managed_group_count: user?.managed_groups?.length || 0,
      })

      setOneSignalSDKLoaded(true)
    } catch (error) {
      console.error(
        'An unexpected error happened initializing OneSignal:',
        error
      )
      setOneSignalSDKLoaded(false)
    }
  })
}

async function updateUserOneSignalId(OneSignal, user, updateUser) {
  try {
    if (OneSignal.User.PushSubscription.optedIn) {
      if (user?.one_signal_id !== OneSignal.User.PushSubscription.id) {
        await updateUser({
          ...user,
          one_signal_id: OneSignal.User.PushSubscription.id,
        })
      }
    }
  } catch (error) {
    console.error('Error updating OneSignal ID:', error)
  }
}

export default OneSignal
