import { yupResolver } from '@hookform/resolvers/yup'
import { setHours, setMinutes } from 'date-fns'
import Router from 'next/router'
import React, { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useSWRConfig } from 'swr'
import handleSetImage from '../../lib/handleSetImage'
import { pathFor } from '../../lib/pathFor'
import setEventEndTime, { combineDateTime } from '../../lib/setEventEndTime'
import useEventCrud from '../../lib/useEventCrud'
import useUser from '../../lib/useUser'
import BasicErrorMessage from '../common/BasicErrorMessage'
import Button from '../common/Button'
import CLDatePicker from '../common/CLDatePicker'
import CLEditor from '../common/CLEditor'
import Checkbox from '../common/Checkbox'
import Icon from '../common/Icon'
import TextInput from '../common/TextInput'
import { useAnalytics } from '../context/AnalyticsProvider'
import { useCacheKey } from '../context/CacheKey'
import AltTextInput from './common/AltTextInput'
import FilePreview from './common/FilePreview'
import eventSchema from './schemas/event'

export default function CreateEvent({
  toggleModal,
  postIn,
  defaultValues,
  changesWithInput,
  setChangesWithInput,
}) {
  const { trackData } = useAnalytics()
  const { mutate } = useSWRConfig()
  const cacheKey = useCacheKey()
  const [submitting, setSubmitting] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [image, setImage] = useState(null)
  const [fileError, setFileError] = useState(null)
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(eventSchema),
    defaultValues: {
      name: defaultValues?.name || null,
      description:
        {
          delta: defaultValues?.content_delta,
        } || null,
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null,
      onlineEvent: defaultValues?.link || false,
      link: defaultValues?.link || null,
      location: defaultValues?.location || null,
      locationDescription: defaultValues?.locationDescription || null,
      altText: null,
    },
  })
  const watchFields = useWatch({ control })
  const { createEvent } = useEventCrud()
  const { user } = useUser()

  useEffect(() => {
    if (
      (watchFields?.name ||
        watchFields?.description ||
        watchFields?.startDate ||
        watchFields?.startTime ||
        watchFields?.endDate ||
        watchFields?.endTime ||
        watchFields?.onlineEvent ||
        watchFields?.link ||
        watchFields?.location ||
        watchFields?.locationDescription ||
        image) &&
      !submitting
    ) {
      setChangesWithInput(true)
    } else {
      setChangesWithInput(false)
    }
  }, [watchFields, image])

  const onSubmit = async (data, e) => {
    if (!postIn?.value) {
      setErrorMsg('Please select a group to post in')
      return
    }

    setSubmitting(true)
    setChangesWithInput(false)
    setErrorMsg(null)

    const {
      name,
      startDate,
      startTime,
      endDate,
      endTime,
      link,
      location,
      locationDescription,
      description,
      altText,
    } = data

    const body = {
      name,
      content_html: description.html,
      content_delta: JSON.stringify(description.delta),
      eventable_type: postIn?.postable_type,
      eventable_id: postIn?.value,
      starts_at: new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        startTime.getHours(),
        startTime.getMinutes(),
        0
      ),
      ends_at: new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        endTime.getHours(),
        endTime.getMinutes(),
        0
      ),
      link,
      location,
      location_description: locationDescription,
      image: image?.signedId,
      type: 'Event',
      alt_text: altText,
    }

    try {
      const { event } = await createEvent(body)
      mutate(cacheKey.value)
      reset()
      e.target.reset()
      toggleModal()
      if (event) {
        trackData('event_created', {
          user,
          type: 'event',
          item: event,
        })
        Router.push(pathFor('event', event))
      }
      setSubmitting(false)
    } catch (error) {
      console.error('An unexpected error happened:', error)
      setErrorMsg('There was an error creating your event')
      setSubmitting(false)
      setChangesWithInput(true)
    }
  }

  const handleDiscardEvent = async () => {
    if (changesWithInput) {
      const confirm = await window.confirm(
        'Are you sure you want to discard this event?'
      )
      if (confirm) {
        toggleModal()
        reset()
      }
    } else {
      toggleModal()
      reset()
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-1 flex-col w-full"
    >
      <TextInput
        name="name"
        label="Event title"
        required
        hint={errors?.name?.message}
        register={register}
      />

      <div className="flex flex-col md:flex-row justify-between flex-shrink-0 md:space-x-4">
        <div className="flex flex-row w-full md:w-1/2 gap-2">
          <Controller
            name="startDate"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CLDatePicker
                selected={value}
                onChange={onChange}
                name="startDate"
                label="Start date"
                dateFormat="dd/MM/yy"
                placeholderText="DD/MM/YY"
                minDate={new Date()}
                required
                hint={errors?.startDate?.message}
                className="w-1/2"
              />
            )}
          />
          <Controller
            name="startTime"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CLDatePicker
                selected={value}
                onChange={(e) => {
                  if (e?.target?.value) {
                    const newValue = combineDateTime(
                      watchFields?.startDate,
                      e?.target?.value
                    )

                    return onChange(newValue)
                  }

                  return onChange(e)
                }}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="h:mm aa"
                placeholderText="00:00"
                icon="clock"
                name="startTime"
                hint={errors?.startTime?.message}
                label="Start time"
                required
                className="w-1/2"
              />
            )}
          />
        </div>

        <div className="flex flex-row w-full md:w-1/2 flex-shrink-0 gap-2">
          <Controller
            name="endDate"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CLDatePicker
                selected={value}
                onChange={onChange}
                name="endDate"
                label="End date"
                dateFormat="dd/MM/yy"
                placeholderText="DD/MM/YY"
                minDate={new Date()}
                required
                hint={errors?.endDate?.message}
                className="w-1/2"
              />
            )}
          />
          <Controller
            name="endTime"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CLDatePicker
                selected={value}
                onChange={(e) => {
                  if (e?.target?.value) {
                    const newValue = combineDateTime(
                      watchFields?.endDate,
                      e?.target?.value
                    )

                    return onChange(newValue)
                  }

                  return onChange(e)
                }}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="h:mm aa"
                placeholderText="00:00PM"
                icon="clock"
                name="endTime"
                hint={errors?.endTime?.message}
                minTime={setEventEndTime(
                  watchFields?.startDate,
                  watchFields?.startTime,
                  watchFields?.endDate
                )}
                maxTime={setHours(setMinutes(new Date(), 30), 23)}
                label="End Time"
                required
                className="w-1/2"
              />
            )}
          />
        </div>
      </div>

      <Checkbox name="onlineEvent" register={register} className="mt-3">
        This is an online event
      </Checkbox>

      {watchFields?.onlineEvent && (
        <TextInput
          name="link"
          label="Event link"
          required
          hint={errors?.link?.message}
          register={register}
        />
      )}
      {!watchFields?.onlineEvent && (
        <>
          <TextInput
            name="locationDescription"
            label="Describe the spot"
            hint={errors?.locationDescription?.message}
            register={register}
          />

          <TextInput
            name="location"
            label="Full event address "
            required
            hint={errors?.location?.message}
            register={register}
          />
        </>
      )}

      <span className="font-bold my-2">Featured photo</span>
      <BasicErrorMessage error={fileError} />
      <div className="mb-4">
        <label
          htmlFor="upload-image-button"
          className="mr-2 px-5 py-3 rounded w-1/2 font-bold text-off-black flex-row flex items-center bg-alt-bg cursor-pointer"
        >
          <Icon name="image" size={24} color="off-black" />
          Choose Image
        </label>
        <input
          type="file"
          id="upload-image-button"
          className="hidden"
          onChange={(e) => handleSetImage(e, setImage, setFileError)}
          accept="image/*"
        />
      </div>
      {image && (
        <div className="mb-4">
          <FilePreview
            preview={image.preview}
            filename={image?.raw?.name}
            type="image"
            onClick={() => {
              setValue('altText', null, { shouldValidate: true })
              setImage(null)
            }}
          />
          <AltTextInput register={register} />
        </div>
      )}

      <Controller
        name="description"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <CLEditor
            value={value?.delta}
            onChange={onChange}
            label="Event description"
            required
            hint={
              errors?.description?.message || errors?.description?.html?.message
            }
            topicHint
            delta
            groupId={postIn?.value}
          />
        )}
      />

      <BasicErrorMessage error={errorMsg} />

      <div className="flex flex-row justify-end my-2">
        <Button
          noPadding
          className="mr-2 px-5 py-2 text-off-black"
          onClick={handleDiscardEvent}
        >
          Discard
        </Button>
        <input
          type="submit"
          value="Create Event"
          disabled={submitting || !postIn?.value}
          className={`px-8 py-2 rounded-full text-white bg-primary  ${
            !postIn?.value
              ? 'opacity-20 cursor-not-allowed'
              : 'opacity-100 cursor-pointer'
          }`}
        />
      </div>
    </form>
  )
}
