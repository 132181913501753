import CryptoJS from 'crypto-js'

export const directUpload = async (
  file,
  onProgress = () => {},
  onStatus = () => {}
) => {
  if (!file.size || file.size === 0) {
    throw new Error('File is empty (0 bytes)')
  }

  // Add network check at the start
  if (!navigator.onLine) {
    throw new Error('Please check your internet connection and try again')
  }

  try {
    onStatus('Preparing upload...')
    const contentType = file.type || determineContentType(file.name)
    const checksum = await calculateMD5(file)

    onStatus('Getting upload URL...')
    const presignedData = await getPresignedUrl({
      filename: file.name,
      byte_size: file.size,
      checksum,
      content_type: contentType,
    })

    if (!presignedData?.direct_upload?.url) {
      throw new Error('Invalid presigned URL response')
    }

    onStatus('Uploading file...')
    await uploadToS3({
      file,
      url: presignedData.direct_upload.url,
      headers: {
        'Content-Type': contentType,
        ...presignedData.direct_upload.headers,
      },
      onProgress,
    })

    onStatus('Upload complete')
    return {
      signedId: presignedData.signed_id,
      preview: URL.createObjectURL(file),
      raw: file,
    }
  } catch (error) {
    if (!navigator.onLine) {
      throw new Error(
        'Your internet connection was lost. Please check your connection and try again'
      )
    }
    throw new Error('Unable to upload your file. Please try again')
  }
}

const getPresignedUrl = async (payload) => {
  const formattedPayload = {
    blob: {
      filename: payload.filename,
      byte_size: parseInt(payload.byte_size, 10),
      checksum: payload.checksum,
      content_type: payload.content_type,
    },
  }

  const response = await fetch('/api/direct_uploads', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(formattedPayload),
  })

  const rawResponse = await response.text()
  let responseData
  try {
    responseData = JSON.parse(rawResponse)
  } catch (parseError) {
    throw new Error('Sorry, we received an unexpected response from the server')
  }

  if (!response.ok) {
    throw new Error(`Unable to prepare the upload. Please try again later`)
  }

  return responseData
}

const uploadToS3 = ({ file, url, headers, onProgress }) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    let uploadComplete = false

    // Add timeout handling
    const timeout = setTimeout(() => {
      if (!uploadComplete) {
        xhr.abort()
        reject(
          new Error(
            'The upload is taking longer than expected. Please try again'
          )
        )
      }
    }, 5 * 60 * 1000) // 5 minute timeout

    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        const percent = (event.loaded / event.total) * 100
        onProgress(percent)
      }
    })

    xhr.addEventListener('load', () => {
      uploadComplete = true
      clearTimeout(timeout)

      if (xhr.status >= 200 && xhr.status < 300) {
        resolve()
      } else {
        console.error('Upload failed:', xhr.statusText)
        console.error('Response:', xhr.responseText)
        reject(new Error('Unable to complete the upload. Please try again'))
      }
    })

    xhr.addEventListener('error', (e) => {
      uploadComplete = true
      clearTimeout(timeout)
      console.log(e)
      console.error('Network error during upload')
      reject(
        new Error(
          'There was a problem with your connection. Please check your internet and try again'
        )
      )
    })

    xhr.addEventListener('abort', () => {
      uploadComplete = true
      clearTimeout(timeout)
      reject(new Error('The upload was cancelled'))
    })

    xhr.open('PUT', url)
    Object.entries(headers).forEach(([key, value]) => {
      xhr.setRequestHeader(key, value)
    })
    xhr.send(file)
  })

const calculateMD5 = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      const binary = e.target.result
      const wordArray = CryptoJS.lib.WordArray.create(binary)
      const md5Hash = CryptoJS.MD5(wordArray)
      const base64String = md5Hash.toString(CryptoJS.enc.Base64)
      resolve(base64String)
    }
    reader.onerror = (e) => reject(e)
    reader.readAsArrayBuffer(file)
  })

const determineContentType = (filename) => {
  const extension = filename.split('.').pop().toLowerCase()
  const mimeTypes = {
    // Images
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    webp: 'image/webp',
    svg: 'image/svg+xml',
    bmp: 'image/bmp',
    tiff: 'image/tiff',
    ico: 'image/x-icon',

    // Documents
    pdf: 'application/pdf',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    odt: 'application/vnd.oasis.opendocument.text',
    ods: 'application/vnd.oasis.opendocument.spreadsheet',
    odp: 'application/vnd.oasis.opendocument.presentation',
    rtf: 'application/rtf',
    txt: 'text/plain',

    // Audio
    mp3: 'audio/mpeg',
    wav: 'audio/wav',
    ogg: 'audio/ogg',
    m4a: 'audio/mp4',
    aac: 'audio/aac',
    wma: 'audio/x-ms-wma',
    flac: 'audio/flac',
    aiff: 'audio/x-aiff',
    mid: 'audio/midi',
    midi: 'audio/midi',

    // Video
    mp4: 'video/mp4',
    avi: 'video/x-msvideo',
    wmv: 'video/x-ms-wmv',
    mov: 'video/quicktime',
    flv: 'video/x-flv',
    mkv: 'video/x-matroska',
    webm: 'video/webm',
    mpeg: 'video/mpeg',
    m4v: 'video/mp4',
    '3gp': 'video/3gpp',

    // Archives
    zip: 'application/zip',
    rar: 'application/x-rar-compressed',
    '7z': 'application/x-7z-compressed',
    tar: 'application/x-tar',
    gz: 'application/gzip',

    // Code and markup
    html: 'text/html',
    htm: 'text/html',
    css: 'text/css',
    js: 'text/javascript',
    json: 'application/json',
    xml: 'application/xml',
    csv: 'text/csv',

    // Other common formats
    eml: 'message/rfc822',
    msg: 'application/vnd.ms-outlook',
    dwg: 'application/acad', // AutoCAD
    ai: 'application/postscript', // Adobe Illustrator
    eps: 'application/postscript',
    psd: 'image/vnd.adobe.photoshop',

    // E-book formats
    epub: 'application/epub+zip',
    mobi: 'application/x-mobipocket-ebook',
    azw: 'application/vnd.amazon.ebook',

    // Font formats
    ttf: 'font/ttf',
    otf: 'font/otf',
    woff: 'font/woff',
    woff2: 'font/woff2',
  }

  return mimeTypes[extension] || 'application/octet-stream' // default binary type
}
