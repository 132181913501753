import { yupResolver } from '@hookform/resolvers/yup'
import { format, setHours, setMinutes } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import checkForFormChanges from '../../lib/checkForFormChanges'
import handleSetImage from '../../lib/handleSetImage'
import setEventEndTime, { combineDateTime } from '../../lib/setEventEndTime'
import useEventCrud from '../../lib/useEventCrud'
import { useLeavePageConfirmation } from '../../lib/useLeavePageConfirmation'
import BasicErrorMessage from '../common/BasicErrorMessage'
import Button from '../common/Button'
import CLDatePicker from '../common/CLDatePicker'
import CLEditor from '../common/CLEditor'
import Checkbox from '../common/Checkbox'
import Icon from '../common/Icon'
import TextInput from '../common/TextInput'
import AltTextInput from './common/AltTextInput'
import FilePreview from './common/FilePreview'
import editEvent from './defaults/editEvent'
import editEventSchema from './schemas/editEvent'

export default function EditEvent({
  toggleModal,
  event,
  setModalChangesWithInput,
}) {
  const { updateEvent } = useEventCrud()
  const [errorMsg, setErrorMsg] = useState(null)
  const [image, setImage] = useState(event?.image || null)
  const [fileError, setFileError] = useState(null)
  const [changesWithInput, setChangesWithInput] = useState(false)
  const defaultValues = editEvent(event)
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(editEventSchema),
    defaultValues,
  })

  const watchFields = useWatch({ control })
  useLeavePageConfirmation(
    changesWithInput,
    'Are you sure you want to discard these changes?'
  )

  useEffect(() => {
    const isFieldChanged = checkForFormChanges(watchFields, defaultValues)
    setChangesWithInput(isFieldChanged)
    setModalChangesWithInput(isFieldChanged)
  }, [watchFields, defaultValues])

  const onSubmit = async (data, e) => {
    setErrorMsg(null)
    const {
      name,
      startDate,
      startTime,
      endDate,
      endTime,
      link,
      location,
      locationDescription,
      description,
      altText,
    } = data

    const body = {
      name,
      id: event?.id,
      slug: event?.slug,
      content_html: description.html,
      content_delta: JSON.stringify(description.delta),
      starts_at: new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        startTime.getHours(),
        startTime.getMinutes(),
        0
      ),
      ends_at: new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        endTime.getHours(),
        endTime.getMinutes(),
        0
      ),
      link: watchFields?.onlineEvent ? link : '',
      location: !watchFields?.onlineEvent ? location : '',
      location_description: !watchFields?.onlineEvent
        ? locationDescription
        : '',
      image: image?.signedId,
      alt_text: altText,
    }

    try {
      await updateEvent(body)
      reset()
      e.target.reset()
      toggleModal()
    } catch (error) {
      console.error('An unexpected error happened:', error)
      setErrorMsg('There was an error updating your event')
    }
  }

  const handleDiscard = async () => {
    const shouldDiscard =
      !changesWithInput ||
      (await window.confirm('Are you sure you want to discard these changes?'))

    if (shouldDiscard) {
      toggleModal()
      reset()
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-1 flex-col w-full px-2 md:px-0"
    >
      <p className="text-red-600">{errorMsg}</p>
      <TextInput
        name="name"
        label="Event Title"
        required
        hint={
          errors?.title?.message ||
          'Note the url may continue to include the old name'
        }
        register={register}
      />

      <div className="flex flex-col md:flex-row justify-between flex-shrink-0 md:space-x-4">
        <div className="flex flex-row w-full md:w-1/2 gap-2">
          <Controller
            name="startDate"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CLDatePicker
                selected={
                  window.Modernizr?.inputtypes
                    ? format(new Date(value), 'yyyy-MM-dd')
                    : value
                }
                onChange={onChange}
                name="startDate"
                label="Start date"
                dateFormat="dd/MM/yy"
                placeholderText="DD/MM/YY"
                minDate={new Date()}
                required
                hint={errors?.startDate?.message}
                className="w-1/2"
              />
            )}
          />
          <Controller
            name="startTime"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CLDatePicker
                selected={
                  window.Modernizr?.inputtypes
                    ? format(new Date(value), 'HH:mm')
                    : value
                }
                onChange={(e) => {
                  if (e?.target?.value) {
                    const newValue = combineDateTime(
                      watchFields?.startDate,
                      e?.target?.value
                    )

                    return onChange(newValue)
                  }

                  return onChange(e)
                }}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="h:mm aa"
                placeholderText="00:00"
                icon="clock"
                name="startTime"
                hint={errors?.startTime?.message}
                label="Start time"
                required
                className="w-1/2"
              />
            )}
          />
        </div>

        <div className="flex flex-row w-full md:w-1/2 flex-shrink-0 gap-2">
          <Controller
            name="endDate"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CLDatePicker
                selected={
                  window.Modernizr?.inputtypes
                    ? format(new Date(value), 'yyyy-MM-dd')
                    : value
                }
                onChange={onChange}
                name="endDate"
                label="End date"
                dateFormat="dd/MM/yy"
                placeholderText="DD/MM/YY"
                minDate={new Date()}
                required
                hint={errors?.endDate?.message}
                className="w-1/2"
              />
            )}
          />
          <Controller
            name="endTime"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CLDatePicker
                selected={
                  window.Modernizr?.inputtypes
                    ? format(new Date(value), 'HH:mm')
                    : value
                }
                onChange={(e) => {
                  if (e?.target?.value) {
                    const newValue = combineDateTime(
                      watchFields?.endDate,
                      e?.target?.value
                    )

                    return onChange(newValue)
                  }

                  return onChange(e)
                }}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="h:mm aa"
                placeholderText="00:00PM"
                icon="clock"
                name="endTime"
                hint={errors?.endTime?.message}
                minTime={setEventEndTime(
                  watchFields?.startDate,
                  watchFields?.startTime,
                  watchFields?.endDate
                )}
                maxTime={setHours(setMinutes(new Date(), 30), 23)}
                label="End Time"
                required
                className="w-1/2"
              />
            )}
          />
        </div>
      </div>

      <Checkbox name="onlineEvent" register={register} className="mt-3">
        This is an online event
      </Checkbox>

      {watchFields?.onlineEvent && (
        <TextInput
          name="link"
          label="Event link"
          required
          hint={errors?.link?.message}
          register={register}
        />
      )}
      {!watchFields?.onlineEvent && (
        <>
          <TextInput
            name="locationDescription"
            label="Describe the spot"
            hint={errors?.locationDescription?.message}
            register={register}
          />

          <TextInput
            name="location"
            label="Full event address "
            required
            hint={errors?.location?.message}
            register={register}
          />
        </>
      )}

      <span className="font-bold my-2">Featured photo</span>
      <BasicErrorMessage error={fileError} />
      <div className="mb-4">
        <label
          htmlFor="upload-image-button"
          className="mr-2 px-5 py-3 rounded w-1/2 font-bold text-off-black flex-row flex items-center bg-alt-bg cursor-pointer"
        >
          <Icon name="image" size={24} color="off-black" />
          Choose Image
        </label>
        <input
          type="file"
          id="upload-image-button"
          className="hidden"
          onChange={(e) => handleSetImage(e, setImage, setFileError)}
          accept="image/*"
        />
      </div>
      {image && (
        <div className="mb-4">
          <FilePreview
            preview={image?.preview || image}
            filename={image?.raw?.name}
            type="image"
            onClick={() => {
              setValue('altText', null, { shouldValidate: true })
              setImage(null)
            }}
          />
          <AltTextInput register={register} showText={getValues('altText')} />
        </div>
      )}

      <Controller
        name="description"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <CLEditor
            value={value?.delta}
            onChange={onChange}
            label="Event description"
            required
            hint={
              errors?.description?.message || errors?.description?.html?.message
            }
            topicHint
            delta
            groupId={event?.eventable?.id}
          />
        )}
      />

      <div className="flex flex-row justify-end my-2">
        <Button
          noPadding
          className="mr-2 px-5 py-2 text-off-black"
          onClick={handleDiscard}
        >
          Discard
        </Button>
        <input
          type="submit"
          value="Update Event"
          className="px-8 py-2 rounded-full text-white cursor-pointer bg-primary"
        />
      </div>
    </form>
  )
}
